import React, { useEffect, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';

import StepperDots from '../../../StepperDots';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import {
  useCreateTaskTemplateMutation,
  useUpdateTaskTemplateMutation,
} from '../../../../services/tasksSlice/tasksSlice';
import { TaskTemplate } from '../../../../../shared/types/Task';
import { useGetUserQuery } from '../../../../services/usersSlice';

interface AddAdminTaskProps {
  selectedTask: TaskTemplate;
  onClose: () => void;
}

const AddAdminTask = ({ selectedTask, onClose }: AddAdminTaskProps) => {
  const [createTaskTemplate] = useCreateTaskTemplateMutation();
  const [updateTaskTemplate] = useUpdateTaskTemplateMutation();
  const [currentStep, setCurrentStep] = useState(0);
  const [description, setDescription] = useState('');
  const [task, setTask] = useState(selectedTask);
  const { data: userADInfo } = useGetUserQuery(selectedTask.defaultAssignee as string  | undefined, {
    skip: selectedTask.defaultAssignee == 'requestor' || selectedTask.defaultAssignee == 'stakeholder' || task.defaultAssignee == 'Other',
  });

  useEffect(() => {
    setDescription(selectedTask.description ?? '');
    setTask(selectedTask);

  }, [selectedTask]);


  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let checked;
    const { name, value } = e.target;
    
    if (e.target instanceof HTMLInputElement) {
      checked = e.target.checked;
    }
    setTask({ ...task, [name]: name === 'review' ? checked : value });
  };

  const handleChangeOfDefaultAssignee = (
    defaultAssignee: string
  ) => {
   console.log("REached here---",defaultAssignee)
   setTask({ ...task, 'defaultAssignee':defaultAssignee});

  };

  const handleCancel = () => {
    if (currentStep === 0) {
      setDescription('');
      setTask(selectedTask);
      onClose();
    } else {
      setCurrentStep(0);
    }
  };

  const buttonValidation =
    currentStep === 0 ? !task.name || !task.shortName : !task.departmentID;

  const handleSave = (type: string) => {
    console.log("Task inside Add task comonent--",task)
    const { name, shortName, defaultAssignee, departmentID, review, phase, stakeholderSummary, requestorSummary, groupName, groupId, taskAge } =
      task;
    const newTask = {
      name,
      shortName,
      description,
      departmentID,
      review,
      phase,
      defaultAssignee: defaultAssignee || null,
      stakeholderSummary,
      requestorSummary,
      groupName,
      groupId : groupId || null,
      taskAge : taskAge || null
    };
    if (currentStep === 0) {
      setCurrentStep(1);
    } else {
      type === 'Add'
        ? createTaskTemplate(newTask)
        : updateTaskTemplate({ id: selectedTask.id, body: newTask });
      setCurrentStep(0);
      setTask(selectedTask);
      setDescription('');
      onClose();
    }
  };

  return (
    <Box>
      <>
        {currentStep === 0 ? (
          <FirstStep
            taskid={selectedTask?.id}
            task={task}
            handleChange={handleChange}
            description={description}
            setDescription={setDescription}
          />
        ) : (
          <SecondStep 
          task={task} 
          handleChange={handleChange} 
          handleChangeOfDefaultAssignee={handleChangeOfDefaultAssignee} 
          displayName = {(task?.defaultAssignee && task?.defaultAssignee?.length > 11) ? userADInfo?.displayName : null}/>
        )}
      </>
      <StepperDots steps={2} activeStep={currentStep} />
      <Stack
        direction="row"
        display="flex"
        justifyContent="right"
        spacing={2}
        sx={{
          px: 4,
          pb: 4,
        }}
      >
        <Button
          sx={{
            color: '#2372B9',
            textTransform: 'none',
          }}
          variant="text"
          onClick={handleCancel}
        >
          {currentStep === 0 ? 'Cancel' : 'Back'}
        </Button>
        {/* PASS TASK NAME */}
        <Button
          sx={{
            backgroundColor: '#2372B9',
            textTransform: 'none',
          }}
          variant="contained"
          disabled={buttonValidation}
          onClick={() => handleSave(selectedTask.name === '' ? 'Add' : 'Edit')}
          type="submit"
        >
          {currentStep === 0
            ? 'Next'
            : selectedTask.name === ''
            ? 'Add Task'
            : 'Edit Task'}
        </Button>
      </Stack>
    </Box>
  );
};

export default AddAdminTask;
