import React, { useState } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import DropDown from '../../DropDown';
import HoveredIcon from '../../HoveredIconButton';
import HoveredIconConfirm from '../../HoveredIconConfirmButton';
import DataFieldCard from '../DataFieldCard';
import TaskDependencyCard from './TaskDependencyCard.tsx';
import {
  DataField,
  DataFieldLocationType,
  DataFieldWithOptionsAndSingleLocation,
} from '../../../../../shared/types/DataField';
import {
  TaskTemplate,
  Task,
  TaskWithTaskDependency,
} from '../../../../../shared/types/Task';
import { useDeleteTaskTemplateMutation } from '../../../../services/tasksSlice/tasksSlice';
import { AddDataFieldModal } from '../../AddDataFieldModal';
import ModalWidget from '../../../ModalWidget/ModalWidget';
import TaskDependencyModal from '../TaskDependencyModal';

interface TaskCardProps {
  taskTemplate: TaskTemplate;
  onEditTriggered: (toEdit: TaskTemplate) => void;
  tasks: TaskTemplate[];
}

const calculateTotalProps = (
  dependencies: Task[],
  dataFields: DataFieldWithOptionsAndSingleLocation[]
) => {
  const dependencyCount = dependencies.length;
  const dataFieldCount = dataFields.length;
  return `${dependencyCount} Dependencies | ${dataFieldCount} Data Fields `;
};

const TaskTemplateCard = ({
  taskTemplate,
  onEditTriggered,
  tasks,
}: TaskCardProps) => {
  //const preventNestedTask = tasks.filter((task) => task.id !== taskTemplate.id);
  const dependentTasksIDs = taskTemplate?.parentTasks.map(
    (dependency) => dependency.id
  );
  const filteredTasks = tasks.filter(
    (task) =>
      task.id !== taskTemplate.id && !dependentTasksIDs.includes(task.id)
  );
  const [deleteTaskTemplate] = useDeleteTaskTemplateMutation();

  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [handle, setHandle] = useState<string>('');

  const handleDelete = () => {
    deleteTaskTemplate(taskTemplate.id);
  };

  const taskModal =
    handle === 'Add Task Dependency' || handle === 'Edit Task Dependency';

  return (
    <>
      {taskModal && (
        <ModalWidget
          expandModal
          isOpen={taskModal}
          title={handle}
          onClose={() => setHandle('')}
          children={
            <>
              <TaskDependencyModal
                taskTemplate={taskTemplate}
                options={filteredTasks}
                description="Choose a task from the dropdown"
                setHandle={setHandle}
              />
            </>
          }
        />
      )}

      {handle.length ? (
        <AddDataFieldModal
          handle={handle}
          setHandle={setHandle}
          locationID={taskTemplate.id}
          locationType={DataFieldLocationType.Task}
        />
      ) : null}
      <Box
        sx={{
          borderLeft: 'solid 5px #2372B9',
          padding: '30px 40px',
          flexWrap: 'wrap',
          height: !showDetail ? '160px' : '',
        }}
      >
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box alignItems="center">
            <Typography
              component="span"
              sx={{
                fontSize: '18px',
                fontWeight: '700',
              }}
            >
              {taskTemplate.name}
            </Typography>
            &nbsp;&nbsp;
            {(!!taskTemplate.parentTasks.length ||
              !!taskTemplate.taskDataFields.length) && (
              <i
                aria-label="details-toggle"
                className={`fa-sharp fa-solid fa-chevron-${
                  showDetail ? 'up' : 'down'
                }`}
                onClick={() => setShowDetail(!showDetail)}
              ></i>
            )}
          </Box>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              width: '105px',
            }}
          >
            {/* Delete Button for Task Cards... */}
            <HoveredIconConfirm
              icon="fa-regular fa-trash"
              hoverIcon="fa-solid fa-trash"
              onClick={() => handleDelete()}
            />
            <HoveredIcon
              icon="fa-regular fa-pencil"
              hoverIcon="fa-solid fa-pencil"
              onClick={() => onEditTriggered(taskTemplate)}
            />
            <DropDown
              icon="fa-regular fa-square-plus"
              hoverIcon="fa-solid fa-square-plus"
              items={[
                'Add Task Dependency',
                'Apply Data Field(s)',
                'Add Data Field',
              ]}
              onSelect={(e) => setHandle(e)}
            />
          </Stack>
        </Stack>
        <Typography
          component="div"
          sx={{
            fontSize: '11px',
            fontWeight: '700',
          }}
        >
          {/* {taskTemplate.id && 'Starter Screen   '} */}
          <Typography
            component="span"
            sx={{ fontSize: '11px', color: '#646669' }}
          > Task ID : {taskTemplate.id} |  
            {calculateTotalProps(
              taskTemplate.parentTasks,
              taskTemplate.taskDataFields
            )}
          </Typography>
        </Typography>
        <Typography
          component="div"
          sx={{
            paddingTop: '15px',
          }}
          dangerouslySetInnerHTML={{
            __html: `<div 
          style="
          display: inline-block;
          max-width:800px;
          white-space: nowrap;
          overflow:hidden;
          text-overflow: ellipsis; "> ${
            !taskTemplate.description
              ? ''
              : taskTemplate.description.replace(/<[^>]+>/g, '')
          }</div>`,
          }}
        >
          {/* { taskTemplate.description ?? '' } */}
        </Typography>
      </Box>
      <Divider />
      {showDetail && (
        <CompleteCard
          taskDependencies={taskTemplate.parentTasks}
          taskDataFields={taskTemplate?.taskDataFields}
          filteredTasks={filteredTasks}
          taskTemplate={taskTemplate}
        />
      )}
    </>
  );
};

export default TaskTemplateCard;

const CompleteCard = ({
  taskDependencies,
  taskDataFields,
  filteredTasks,
  taskTemplate,
}: any) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#F8F9FA',
          padding: '0px 0px 10px 45px',
        }}
      >
        <br />
        {!!taskDependencies.length && (
          <>
            <Typography
              component="span"
              sx={{
                color: '#646669',
                font: 'normal normal normal 12px/28px Open Sans',
              }}
            >
              To begin this task the following dependencies must be done:
            </Typography>
            <Divider />
          </>
        )}
        {taskDependencies.map(
          (taskDependency: TaskWithTaskDependency, index: number) => {
            return (
              <TaskDependencyCard
                key={taskDependency.id}
                taskTemplate={taskTemplate}
                taskDependency={taskDependency}
                index={index}
                lastElement={taskDependencies.length - 1}
                filteredTasks={filteredTasks}
              />
            );
          }
        )}

        {!!taskDataFields.length && !!taskDependencies.length && (
          <>
            <Divider
              light
              sx={{
                borderBottomWidth: 8,
                marginLeft: '-45px',
                marginRight: '-40px',
              }}
            />
            <Typography
              component="span"
              sx={{
                color: '#646669',
                font: 'normal normal normal 12px/28px Open Sans',
              }}
            >
              These data fields will appear under this task:
            </Typography>
            <Divider />
          </>
        )}
        {taskDataFields.map((dataField: DataField, index: number) => {
         
          return (
            <DataFieldCard
              key={dataField.id}
              dataField={dataField}
              dataFields= {taskDataFields}
              locationName= {taskTemplate?.name}
              locationDescription = {taskTemplate?.description}
              index={index}
              lastElement={taskDataFields.length - 1}
            />
          );
        })}
      </Box>
    </>
  );
};
